<template>
  <div class="score-page">
    <b-container id="styledElement">
      <div class="elemnts-box" id="resizeElement">
        <div class="quiz-box">
          <div class="quiz-content">
            <div class="title-holder">
              <h1 class="quiz-title"><span class="subtitle">YOUR</span>Clean Air Personality Type Results</h1>
            </div>
            <div class="quiz-image">
              <img v-if="mostAnswered === 0" src="../assets/img/I11.png">
              <img v-if="mostAnswered === 1" src="../assets/img/I12.png">
              <img v-if="mostAnswered === 2" src="../assets/img/I13.png">
              <img v-if="mostAnswered === 3" src="../assets/img/I14.png">
            </div>
            <div class="score-holder">
              <span class="score-icon"></span>
              <div v-if="mostAnswered === 0">
                <span class="score-subtitle">If you answered mostly A’s:</span>
                <div class="result">
                  <strong>You’re the Queen/King of Clean (Air)!</strong><br/><br>
                  You’re completely dedicated to the clean air mission and are constantly trying to make the world a better place. Wear your clean air crown with pride and keep doing you! Want to make even more change?
                  Check out our Clean Air Actions list below:
                </div>
              </div>
              <div v-if="mostAnswered === 1">
                <span class="score-subtitle">If you answered mostly B’s:</span>
                <div class="result">
                  <strong>You’re the Fresh Prince/Princess of Clean Air!</strong><br><br>
                  You’re a passionate environmentalist and love the thought of a world with clean air. You have all the right ideas but could definitely do more to raise awareness. So, what will you do next? Our Clean Air Actions list below is a good place to start:
                </div>
              </div>
              <div v-if="mostAnswered === 2">
                <span class="score-subtitle">If you answered mostly C’s:</span>
                <div class="result">
                  <strong>You’re an Air-Venger!</strong><br><br>
                  You believe the planet needs protection, but you don’t fully know how to help. Instead of actively getting involved in the clean air mission, you occasionally sit on the side-lines. Now’s your chance to save the world – check out our
                  Clean Air Actions list below to see how you can help:
                </div>
              </div>
              <div v-if="mostAnswered === 3">
                <span class="score-subtitle">If you answered mostly D’s:</span>
                <div class="result">
                  <strong>You’re an Air Apprentice!</strong><br/><br/>
                  You don’t know much about air pollution and the ways to help, but that’s fine… everyone must start somewhere. By reading up on clean air and getting involved in community projects you could go from Air Apprentice to Air Expert in no time! Our Clean Air Actions list below is a good place to start. What’s stopping you?
                </div>
              </div>
            </div>
            <div class="quiz-bottom" style="padding-left: 0">
              <div class="bottom-left">
                <b-btn class="btn" @click="$router.push({ name: 'Actions' })">CLEAN AIR ACTION LIST!</b-btn>
              </div>
              <div class="bottom-right">
                <img src="../assets/img/Logos.png">
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Score',
  computed: {
    ...mapGetters(['answers']),
    mostAnswered () {
      try {
        return this.answers.filter((x, i, a) => a.indexOf(x) === i).map(answer => {
          return {
            cnt: this.answers.filter(a => a === answer).length,
            answer
          }
        }).sort((a, b) => { return b.cnt - a.cnt })[0].answer
      } catch (e) {
        return false
      }
    }
  },
  mounted () {
    if (this.mostAnswered === false) {
      this.$router.push({ name: 'Intro' })
    }
  }
}
</script>
