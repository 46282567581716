<template>
  <div>
    <b-container id="styledElement">
      <div class="elemnts-box list-page" id="resizeElement">
        <div class="quiz-box">
          <div class="quiz-content">
            <span class="list-icon"></span>
            <div class="title-holder">
              <h1 class="quiz-title"><span class="subtitle">THE CLEAN AIR</span>Actions List</h1>
            </div>
            <ul class="action-list">
              <li>Walk, cycle, scoot or take public transport and leave the car at home, whenever you
                can. Pssst, don’t forget that quieter side streets are less polluted than busy roads –
                better for your health and a chance to chat with friends or listen to music too!
              </li>
              <li>
                Talk to your friends and family about the harms of air pollution and raise awareness
                about what needs to be done to improve it at your school and in your community.
                Try to avoid non-essential deliveries where possible.
              </li>
              <li>
                Switch to milder cleaning and personal care products.
              </li>
              <li>
                Open windows to let fresh air in, especially when people are cooking or cleaning.
              </li>
              <li>
                Ask local and national decision makers what they’re doing to decrease air pollution
                and give suggestions where you can.
              </li>
              <li>
                Get involved with clean air initiatives, such as Clean Air Day... or start your own campaign!
              </li>
            </ul>
            <div class="quiz-bottom">
              <div class="bottom-left">
              </div>
              <div class="bottom-right">
                <img src="../assets/img/Logos.png">
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Actions',
  methods: {
    ...mapMutations(['clearAnswers'])
  },
  mounted () {
    this.clearAnswers()
  }
}
</script>

<style scoped>

</style>
